import React, { Fragment } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Redirect, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import Observer from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TimeAgo from "timeago-react";
import HeaderLogo from "../AppLogo";
import {
  setUserDetail,
  setAddCustomerRedirect,
  setDealerDashboardRedirectDetail,
  setRedirect,
  setHelpGuide,
} from "../../actions/UserAction";
import UserBox from "../../components/UserBox/UserBox";
import "./Header.css";
import UserService from "../../services/UserService";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import Storage from "../../services/Storage";
import { getAction } from "../../utils/utils";
import GoogleAnalticsService from "../../services/GoogleAnalticsService";
import DashboardService from "../../services/DashboardService";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isNotificationVisible: false,
      notificationData: [],
      unreadNotificationCount: {},
      totalNotificationCount: 0,
      redirectToProfile: false,
      redirectToDashboard: false,
      redirectToAbout: false,
      redirectToContactUs: false,
      redirectToMerchandiseWallet: false,
      redirectToReport: false,
      redirectToWarrantyScoreCard: false,
      redirectToAreaManagerWarrantyScorcard: false,
      redirectToAreaManagerOfftakeScorecard: false,
      redirectToAreaManagerDashboard: false,
      imageUrl: "",
      isLoading: false,
      hideNav: [],
      is_display_customer_mgt: false,
      redirectToNewDashboard: false,
      userDetail: Storage.getUserDetail(),
      dashborad_path: "/",
      redirectToCreateCampaign: false,
      redirectToPosterDownload: false,
      tooltipOpen: false,
      tooltip_name: "add_visit_history_popover",
    };
    if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)
    ) {
      this.state.dashborad_path = "/dealer-dashboard";
    }
    this.openNextToolTipPopOver = this.openNextToolTipPopOver.bind(this);
  }

  componentDidMount() {
    this.getHelpModuleScreen();
    this.getUnreadNotificationCount();
    let { is_display_customer_mgt } = this.state;
    is_display_customer_mgt =
      this.state.userDetail.permissions &&
        (this.state.userDetail.permissions.includes(Permissions.ADD_CUSTOMER_VISIT) ===
          true ||
          this.state.userDetail.permissions.includes(Permissions.VISIT_HISTORY) === true)
        ? true
        : false;
    this.setState({
      is_display_customer_mgt,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.helpGuide !== this.props.helpGuide &&
      !!this.props.helpGuide === true &&
      this.props.helpGuide === "add_visit_history_popover"
    ) {
      this.setState({
        tooltip_name: "add_visit_history_popover",
        tooltipOpen: true,
      });
    }
  }

  getHelpModuleScreen = () => {
    this.setState({ isLoading: true });
    DashboardService.getHelpModuleScreen()
      .then((data) => {
        this.setState({ isLoading: false });
        const { screen_name } = data.data.data;
        let location = window.location.href.split("/");
        if (
          screen_name.includes("dealer_dashboard") === false &&
          location[location.length - 1] === "dealer-dashboard"
        ) {
          this.setState({
            tooltipOpen: true,
            tooltip_name: "add_visit_history_popover",
          });
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  openNextToolTipPopOver() {
    this.setState({ tooltipOpen: false, tooltip_name: "" });
    localStorage.setItem("helperModule", "customer_popover");
    this.props.setHelpGuide("customer_popover");
  }

  getNotification = async (obj) => {
    let { totalNotificationCount, isLoading } = this.state;
    try {
      let { notificationData } = this.state;
      const { data } = await UserService.getNotification(obj);
      if (data) {
        notificationData = data.data;
        if (obj.from === "componentDidMount") {
          totalNotificationCount = notificationData.total_count;
          this.setState({ totalNotificationCount });
        }
        this.setState({ notificationData });
      }
      isLoading = false;
      this.setState({ isLoading });
    } catch (error) {
      isLoading = false;
      this.setState({ isLoading });
      console.log(error);
    }
  };

  fetchMoreData = () => {
    const { totalNotificationCount, notificationData } = this.state;
    if (
      ((notificationData || {}).notifications || []).length >=
      totalNotificationCount
    ) {
      return;
    }

    setTimeout(() => {
      this.getNotification({
        page: 1,
        pagesize: ((notificationData || {}).notifications || []).length + 10,
      });
    }, 1000);
  };

  getUnreadNotificationCount = async (obj) => {
    try {
      let { unreadNotificationCount } = this.state;
      const { data } = await UserService.getUnreadNotificationCount(obj);
      if (data) {
        unreadNotificationCount = data.data;

        this.setState({ unreadNotificationCount });
      }
    } catch (error) {
      console.log(error);
    }
  };

  openProfileModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  openCloseNotificationModal = () => {
    let {
      isNotificationVisible,
      unreadNotificationCount,
      isLoading,
    } = this.state;
    unreadNotificationCount = 0;
    isNotificationVisible = !isNotificationVisible;
    if (isNotificationVisible) {
      isLoading = true;
      this.setState({ isLoading });
      this.getNotification({
        page: 1,
        pagesize: 10,
        from: "componentDidMount",
      });
    }
    this.setState({ isNotificationVisible, unreadNotificationCount });
    GoogleAnalticsService.pageView("Notification View");
  };

  redirectToProfile = () => {
    this.setState({ redirectToProfile: true });
  };

  goToAction = (action, notification) => {
    GoogleAnalticsService.event("Notification", "Notification List Click");
    let path_data = getAction(action);
    if (typeof path_data === "string") {
      if (
        notification.notification_data &&
        notification.notification_data.filter_type &&
        notification.notification_data.filter_type.toLowerCase() === "due" &&
        notification.notification_data.due_type &&
        notification.notification_data.due_type.toLowerCase() ===
        "upcoming_due" &&
        notification.notification_data.due_date_from &&
        new Date(
          notification.notification_data.due_date_from
        ).getMilliseconds() < new Date().getMilliseconds()
      ) {
        notification.notification_data.due_type = "over_due";
      }
      if (path_data === "/campaign/create") {
        if (notification.notification_type === "scheduled-campaign-alert") {
          path_data = "/campaign";
        }
        let notificationData = notification.notification_data;
        this.setCampaignData(notificationData);
        this.setState({ redirectToCreateCampaign: true });
      } else if (path_data === "/customer-management") {
        let notificationDataCustomerMgt = notification.notification_data;
        localStorage.setItem("active_id", 1);
        localStorage.setItem("activeLink", "customer-management");
        this.setCustomerData(notificationDataCustomerMgt);
      }
      this.props.history.push(path_data);
    } else {
      if (path_data.isRedeemCouponOpen) {
        localStorage.setItem("redirectDetails", JSON.stringify(path_data));
        this.props.history.push(null);
      } else {
        this.props.setRedirect(path_data);
        if (path_data.pathname) {
          this.props.history.push(path_data.pathname);
        }
      }
    }
    // switch (action) {
    //   case "dashboard":
    //   case "dashboard_ytd":
    //   case "dashboard_mtd":
    //   case "dashboard_qtd":
    //   case "yearly_offtake":
    //   case "check_warranty_eligibility_warranty":
    //   case "add_warranty":
    //     this.setState({ redirectToDashboard: true });
    //     break;

    //   case "report":
    //     this.setState({ redirectToReport: true });
    //     break;

    //   case "profile":
    //   case "profile_request_to_edit":
    //     this.setState({ redirectToProfile: true });
    //     break;

    //   case "about_programme":
    //     this.setState({ redirectToAbout: true });
    //     break;

    //   case "warranty_scorecard":
    //     this.setState({ redirectToWarrantyScoreCard: true });
    //     break;

    //   case "merchandise":
    //     this.setState({ redirectToMerchandiseWallet: true });
    //     break;

    //   case "employee_dashboard":
    //   case "employee_dashboard_mtd":
    //   case "employee_dashboard_ytd":
    //   case "employee_dashboard_qtd":
    //   case "employee_yearly_offtake":
    //     this.setState({ redirectToAreaManagerDashboard: true });
    //     break;

    //   case "employee_warranty_scorecard":
    //     this.setState({ redirectToAreaManagerWarrantyScorcard: true });
    //     break;

    //   case "contact_us":
    //     this.setState({ redirectToContactUs: true });
    //     break;

    //   case "employee_offtake_scorecard":
    //     this.setState({ redirectToAreaManagerOfftakeScorecard: true });
    //     break;
    //   case "campaign":
    //     let notificationData = notification.notification_data;
    //     this.setCampaignData(notificationData);
    //     this.setState({ redirectToCreateCampaign: true });
    //     break;
    //   case "customer_management":
    //     let notificationDataCustomerMgt = notification.notification_data;
    //     localStorage.setItem("active_id", 1);
    //     localStorage.setItem("activeLink", 'customer-management');
    //     this.setCustomerData(notificationDataCustomerMgt);
    //     this.props.history.push('/customer-management');
    //     break;
    //   default:
    //     this.setState({ redirectToDashboard: true });
    //     break;
    // }
    this.setState({ isNotificationVisible: false });
  };

  setCampaignData = (data) => {
    let reqData = {
      campaign: data,
    };
    this.props.setDealerDashboardRedirectDetail(reqData);
  };
  setCustomerData = (data) => {
    let reqData = {
      customer_mgt: data,
    };
    this.props.setDealerDashboardRedirectDetail(reqData);
  };

  viewCloseImage = (url) => {
    let { imageUrl } = this.state;
    imageUrl = url;
    this.setState({ imageUrl });
  };

  redirectToAddCustomer = () => {
    this.setState({ tooltipOpen: false, tooltip_name: "" });
    if (
      this.state.userDetail &&
      (this.state.userDetail.type === Roles.BRANCH_ROLE ||
        this.state.userDetail.type === Roles.SUB_DEALER_ROLE)
    ) {
      let href = window.location.href;
      this.props.setAddCustomerRedirect({ redirectToAddCustomer: true });
      localStorage.setItem("active_id", 1);
      localStorage.setItem("activeLink", this.state.dashborad_path);
      if (href.indexOf("dealer-dashboard") <= -1) {
        this.props.history.push("/warranty-score-card");
      }
    } else if (
      this.state.userDetail.permissions &&
      this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD)
    ) {
      let href = window.location.href;
      this.props.setAddCustomerRedirect({ redirectToAddCustomer: true });
      localStorage.setItem("active_id", 1);
      localStorage.setItem("activeLink", this.state.dashborad_path);
      if (href.indexOf("dealer-dashboard") <= -1) {
        this.props.history.push(this.state.dashborad_path);
      }
    } else {
      let routUrl =
        window.location.href &&
        window.location.href.split("/")[4] &&
        window.location.href.split("/")[4];
      this.props.setAddCustomerRedirect({ redirectToAddCustomer: true });
      if (routUrl) {
        this.props.history.push(this.state.dashborad_path);
      }
    }
    // let { hideNav } = this.state;
    // if (hideNav && hideNav.length > 0) {
    //   let index_new_dash = hideNav.findIndex(x => x && x.toLowerCase() === 'new_dashboard');
    //   if (index_new_dash <= -1) {
    //     let href = window.location.href;
    //     this.props.setAddCustomerRedirect({ redirectToAddCustomer: true })
    //     localStorage.setItem("active_id", 1);
    //     localStorage.setItem("activeLink", this.state.dashborad_path);
    //     if (href.indexOf('dealer-dashboard') <= -1) {
    //       this.props.history.push(this.state.dashborad_path);
    //     }
    //   } else {
    //     let routUrl =
    //       window.location.href &&
    //       window.location.href.split("/")[4] &&
    //       window.location.href.split("/")[4];
    //     this.props.setAddCustomerRedirect({ redirectToAddCustomer: true })
    //     if (routUrl) {
    //       this.props.history.push(this.state.dashborad_path);
    //     }
    //   }
    // } else {
    //   let href = window.location.href;
    //   this.props.setAddCustomerRedirect({ redirectToAddCustomer: true })
    //   if (href.indexOf('dealer-dashboard') <= -1) {
    //     this.props.history.push(this.state.dashborad_path);
    //   }
    // }
  };

  render() {
    const {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow,
    } = this.props;
    const {
      isLoading,
      notificationData,
      unreadNotificationCount,
      // totalNotificationCount,
      // isNotificationVisible,
      redirectToDashboard,
      redirectToProfile,
      redirectToAbout,
      redirectToContactUs,
      redirectToMerchandiseWallet,
      redirectToWarrantyScoreCard,
      redirectToReport,
      redirectToAreaManagerDashboard,
      redirectToAreaManagerWarrantyScorcard,
      redirectToAreaManagerOfftakeScorecard,
      imageUrl,
      // redirectToNewDashboard,
      // hideNav,
      redirectToCreateCampaign,
      redirectToPosterDownload,
    } = this.state;
    return (
      <Fragment>
        {/* {isLoading && <CustomSpinner />} */}
        {redirectToDashboard && <Redirect to={this.state.dashborad_path} />}
        {redirectToProfile && <Redirect to="/profile" />}
        {redirectToReport && (
          <Redirect to={{ pathname: "/primary-points-report" }} />
        )}
        {redirectToAbout && <Redirect to={{ pathname: "/about" }} />}
        {redirectToWarrantyScoreCard && (
          <Redirect to={{ pathname: "/warranty-score-card" }} />
        )}
        {redirectToMerchandiseWallet && (
          <Redirect to={{ pathname: "/merchandise-wallet" }} />
        )}
        {redirectToContactUs && <Redirect to={{ pathname: "/contact-us" }} />}
        {redirectToAreaManagerDashboard && (
          <Redirect to={{ pathname: "/dashboard" }} />
        )}
        {redirectToAreaManagerWarrantyScorcard && (
          <Redirect to={{ pathname: "/warranty-score-card-report" }} />
        )}
        {redirectToAreaManagerOfftakeScorecard && (
          <Redirect to={{ pathname: "/offtake-score-card-report" }} />
        )}
        {redirectToCreateCampaign && (
          <Redirect to={{ pathname: "/campaign/create" }} />
        )}
        {redirectToPosterDownload && (
          <Redirect to={{ pathname: "/download_poster" }} />
        )}

        <div id="header">
          <ReactCSSTransitionGroup
            component="div"
            className={cx("app-header", headerBackgroundColor, {
              "header-shadow": enableHeaderShadow,
            })}
            transitionName="HeaderAnimation"
            transitionAppear
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <HeaderLogo />
            {/* <div>JkTyre</div> */}
            <div
              className={cx("app-header__content", {
                "header-mobile-open": enableMobileMenuSmall,
              })}
            >
              <div className="app-header-left">
                {/* <SearchBox />
              <MegaMenu /> */}
              </div>
              <div className="app-header-right">
                {this.state.is_display_customer_mgt && (
                  <>
                    <Button
                      type="button"
                      className={
                        this.state.tooltipOpen &&
                          this.state.tooltip_name === "customer_popover"
                          ? "box-shadow-40px-0-5 mb-1 mr-1 btn-pill action-button profile-save-button pull-right Add-Customer-Visit-btn"
                          : "mb-1 mr-1 btn-pill action-button profile-save-button pull-right Add-Customer-Visit-btn"
                      }
                      color="warning"
                      id="add_visit_history_popover"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.redirectToAddCustomer()}
                    >
                      <img
                        src="/images/add-customer-icon.svg"
                        alt=""
                        style={{ height: "31px", objectFit: "contain" }}
                      />
                      <span>Add Customer Visit</span>
                    </Button>
                    <UncontrolledPopover
                      placement={"right-start"}
                      hideArrow={false}
                      isOpen={
                        this.state.tooltipOpen &&
                        this.state.tooltip_name === "add_visit_history_popover"
                      }
                      fade={true}
                      target={"add_visit_history_popover"}
                      trigger="focus"
                      className="dealer-popover"
                    >
                      <div ref={(r) => (this.dealerPopOver = r)}>
                        <PopoverHeader className="popover-title d-flex justify-content-between">
                          <span>Help Guide Tour</span>
                          <span>1/7</span>
                        </PopoverHeader>
                        <PopoverBody className="d-flex-column">
                          <span className="popover-lbl mt-2">
                            Add details of all the customers
                          </span>
                          <span className="popover-lbl-value mt-2">
                            Capture information of each and every store visitor,
                            be it for Tyre Purchase or Service or just an
                            inquiry
                          </span>
                          <br />
                          <div className="d-flex-row justify-content-end mb-2">
                            <span
                              className="link-blue cursor-pointer font-size-14px font-weight-bold"
                              onClick={this.openNextToolTipPopOver}
                            >
                              Next
                            </span>
                          </div>
                        </PopoverBody>
                      </div>
                    </UncontrolledPopover>
                    <span className="border-0-5-dee2e6"></span>
                  </>
                )}
                {/* <HeaderDots /> */}
                {/* <div className="header-btn-lg">
                  <img className="header-menus" src="images/warranty.svg" alt="" />
                </div> */}
                <div>
                  <UncontrolledButtonDropdown id="notification-box">
                    <DropdownToggle onClick={this.openCloseNotificationModal}>
                      <img
                        className="header-menus"
                        src="images/notif.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                      {unreadNotificationCount.count > 0 && (
                        <Badge
                          color="danger"
                          style={{
                            backgroundColor: "#fdbb1f",
                            top: "6px",
                            marginLeft: "-12px",
                            position: "absolute",
                          }}
                        >
                          {unreadNotificationCount.count}
                        </Badge>
                      )}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        {isLoading && <CustomSpinner />}

                        <div
                          style={{ width: "100%" }}
                        // className="arrow_box"
                        >
                          <Card
                            style={{
                              // marginLeft: '-280%',
                              height: "500px",
                              overflow: "auto",
                            }}
                          >
                            <CardBody>
                              <Row>
                                <Col>
                                  {(
                                    (notificationData || {}).notifications || []
                                  ).map((notification, index) => {
                                    return (
                                      <div
                                        key={notification.uuid}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Row
                                          onClick={() =>
                                            this.goToAction(
                                              notification.action,
                                              notification
                                            )
                                          }
                                        >
                                          <Col>
                                            <div>
                                              <span className="title">
                                                {notification.title}
                                              </span>
                                              <span className="notification-date-time custom-pull-right">
                                                <TimeAgo
                                                  datetime={
                                                    notification.created_at
                                                  }
                                                  locale="zh"
                                                />
                                              </span>
                                            </div>

                                            <div
                                              className="notification-desc"
                                              style={{ marginTop: "5px" }}
                                            >
                                              {notification.message}
                                            </div>
                                          </Col>
                                        </Row>
                                        {notification.image_url && (
                                          <Row style={{ marginTop: "8px" }}>
                                            <Col>
                                              <span
                                                className="custom-anchor-tag pull-right"
                                                onClick={() =>
                                                  this.viewCloseImage(
                                                    notification.image_url
                                                  )
                                                }
                                              >
                                                View Image
                                              </span>
                                            </Col>
                                          </Row>
                                        )}
                                        {index + 1 ===
                                          (
                                            (notificationData || {})
                                              .notifications || []
                                          ).length && (
                                            <Observer
                                              onChange={this.fetchMoreData}
                                            >
                                              <div>&nbsp;</div>
                                            </Observer>
                                          )}
                                        <hr />
                                      </div>
                                    );
                                  })}

                                  {(
                                    (notificationData || {}).notifications || []
                                  ).length === 0 && (
                                      <>
                                        <h6>No notification found</h6>
                                        <hr />
                                      </>
                                    )}
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>

                  {/* <img className="header-menus" onClick={this.openCloseNotificationModal} src="images/notif.svg" alt="" style={{ cursor: 'pointer' }} /> */}
                </div>
                {imageUrl && (
                  <div
                    style={{
                      zIndex: "2000",
                      height: "630px",
                      width: "100%",
                      top: "0",
                      left: "0",
                      position: "absolute",
                      background: "url(./images/transparent_bg.png)",
                    }}
                  >
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <div onClick={() => this.viewCloseImage("")}>
                              &nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon
                                color="#ed3f32"
                                className="pull-right"
                                icon={faTimes}
                                style={{
                                  fontSize: "35px",
                                  cursor: "pointer",
                                  marginRight: "22px",
                                  marginTop: "34px",
                                }}
                              />
                              {/* <span className="pull-right">Close</span> */}
                            </div>
                          </Col>
                        </Row>
                        <br />
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={imageUrl}
                            alt={imageUrl}
                            width="650"
                            height="620"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <div className="header-btn-lg">
                  <UserBox customerClass={this.state.userDetail.customer_class} />
                </div>

                {/* <UserBox /> */}
                {/* <div className="header-btn-lg">Standard Tyres & Batteries</div> */}
              </div>
            </div>
          </ReactCSSTransitionGroup>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserDetail: (user) => dispatch(setUserDetail(user)),
  setDealerDashboardRedirectDetail: (redirectDetails) =>
    dispatch(setDealerDashboardRedirectDetail(redirectDetails)),
  setAddCustomerRedirect: (redirectDetailsAddCustomer) =>
    dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
  setRedirect: (redirectDetails) => dispatch(setRedirect(redirectDetails)),
  setHelpGuide: (helperModule) => dispatch(setHelpGuide(helperModule)),
});

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  userDetail: state.User.userDetail,
  helpGuide: state.User.helpGuide,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
